import React from "react";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";

function TermsAndConditionsPage() {
  return (
    <>
      <Header></Header>

      <div className="page-content">
        <div>
          <div>
            <b>Terms and Conditions</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>1. Acceptance of Terms</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            By accessing or using the Carabao E-commerce Energy Drink website,
            you agree to comply with and be bound by these Terms and Conditions.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>2. Use of the Website</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            You agree to use the website for lawful purposes only and in a
            manner consistent with all applicable laws and regulations.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>3. Intellectual Property</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            All content on this website, including text, graphics, logos, and
            images, is the property of Carabao E-commerce Energy Drink and is
            protected by intellectual property laws. You may not reproduce,
            distribute, or display any portion of the website without explicit
            permission.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>4. Product Information</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            Product descriptions, prices, and availability are subject to change
            without notice. Carabao E-commerce Energy Drink reserves the right
            to modify or discontinue products at any time.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>5. User Accounts</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            To access certain features of the website, you may be required to
            create a user account. You are responsible for maintaining the
            confidentiality of your account information and for restricting
            access to your computer or device.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>6. Limitation of Liability</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            Carabao E-commerce Energy Drink is not liable for any direct,
            indirect, incidental, consequential, or punitive damages arising out
            of your access to or use of the website.
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>7. Governing Law</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Jurisdiction]. Any disputes
            arising from or related to these terms shall be subject to the
            exclusive jurisdiction of the courts in [Your Jurisdiction].
          </div>
          <div>
            <br />
          </div>
          <div>
            <b>8. Changes to Terms and Conditions</b>
          </div>
          <div>
            <br />
          </div>
          <div>
            Carabao E-commerce Energy Drink reserves the right to update or
            modify these Terms and Conditions at any time. It is your
            responsibility to review this page periodically for changes.
          </div>
          <div>
            <br />
          </div>
          <div>
            By using this website, you agree to these Terms and Conditions. If
            you do not agree, please refrain from using the website.
          </div>
          <div>
            <br />
          </div>
          <div>Last updated:</div>
        </div>
        <div>
          <br />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default TermsAndConditionsPage;
