import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { ApiUrl, addToCartApi } from '../helpers/helpers';
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import main_product_text_bigger_img from "../images/main_product_text_bigger_img.svg";
import about_page_lights_left from "../images/about_page_lights_left.png";
import hurdle_layer_img from "../images/hurdle_layer_img.svg";
import login_page_banner from "../images/login_page_banner.png";
import { useNavigate } from 'react-router-dom';


function SignUpPage() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const registerUser = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);

      const response = await axios.post(`${ApiUrl}register`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      }); 

      const { token, first_name, email } = response.data.success;
      localStorage.setItem('token', token);
      localStorage.setItem('first_name', first_name);
      localStorage.setItem('email', email);
      toast.success('User Register Successfully');
      navigate('/');
    } catch (error) {
      toast.error(error.response.data);
      console.error('Registration error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className="product_page_banner login_banner_section">
        <span className="main_product_text_bigger_img">
          <img src={main_product_text_bigger_img} alt="main_product_text_bigger_img" />
        </span>
      </section>
      <div className="grids">
        <section className="our_story_section login_form_area">
          <span className="light_left">
            <img src={about_page_lights_left} alt="about_page_lights_left" />
          </span>
          <span className="light_right">
            <img src={about_page_lights_left} alt="about_page_lights_left" />
          </span>

          <div className="container">
            <div className="login_form">
              <span className="hurdle_layer">
                <img src={hurdle_layer_img} alt="hurdle_layer_img" />
              </span>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="login_form_img">
                    <img src={login_page_banner} alt="login_banner_section" />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 mt-3">
                  <div className="login_header">
                    <h2>
                      <span> Sign Up </span>
                    </h2>
                  </div>

                  <form onSubmit={registerUser}>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            value={userData.first_name}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="last_name"
                            value={userData.last_name}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={userData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={userData.password}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 px-4 mt-5">
                        <div className="text-center">
                          <button type="submit" className="btn view_more_btn" disabled={loading}>
                            {loading ? 'Registering...' : 'Register'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="text-center"> 
                    <h4 className="dont_have_account_text">Already have an account ? <Link to="/login">Login</Link> </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Toaster />
      <Footer />
    </>
  );
}

export default SignUpPage;
