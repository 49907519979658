import React from "react";
import "../css/style.css";
import about_page_lights_left from "../images/about_page_lights_left.png";
import footer_layer_img from "../images/footer_layer_img.png";
import our_story_bottle_img from "../images/our_story_bottle_img.png";
import champion_cup_img from "../images/champion_cup_img.png";
import carabao_cup_big_image from "../images/carabao_cup_big_image.png";
import ContactUs from "./components/ContactUs";

import Header from "../inculde/Header";
import Footer from "../inculde/Footer";

function ContactUsPage() {
  return (
    <>
      <Header></Header>

      {/* <div className="page-content">
         <div>
          <div>
            <div>
              <div>
                <div>
                  <b>Contact Us</b>
                  <br className="Apple-interchange-newline" />
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    If you have any questions, concerns, or feedback, we'd love
                    to hear from you. Here's how you can reach us:
                  </b>
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    **Customer Support:**
                    <br />
                    <br />
                  </b>
                </div>
                <div>
                  - <b>Email:</b> support@carabaoenergy.com
                </div>
                <div>
                  - <b>Phone:</b> +1 (555) 123-4567
                </div>
                <div>
                  - <b>Operating Hours:</b> Monday to Friday, 9:00 AM - 5:00 PM
                  (EST)
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    **Visit Us:**
                    <br />
                    <br />
                  </b>
                </div>
                <div>Carabao E-commerce Energy Drink</div>
                <div>123 Energy Lane</div>
                <div>Cityville, Stateville, 12345</div>
                <div>
                  <b>
                    <br />
                  </b>
                </div>
                <div>
                  <b>
                    **Social Media:**
                    <br />
                    <br />
                  </b>
                </div>
                <div>
                  <b>
                    Connect with us on social media for updates, promotions, and
                    more:
                  </b>
                  <br />
                  <br />
                </div>
                <div>
                  - <b>Facebook:</b> facebook.com/CarabaoEnergy
                </div>
                <div>
                  - <b>Twitter: </b>twitter.com/CarabaoEnergy
                </div>
                <div>
                  - <b>Instagram:</b> instagram.com/CarabaoEnergy
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    **Send Us a Message:**
                    <br />
                  </b>
                  <br />
                </div>
                <div>
                  Use the form below to send us a message. We'll get back to you
                  as soon as possible.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <br />
                </div>
                <div>
                  <b>
                    **Feedback:**
                    <br />
                    <br />
                  </b>
                </div>
                <div>
                  We value your feedback. Let us know how we can improve our
                  products and services.
                </div>
                <div>
                  <br />
                </div>
                <div>
                  Thank you for choosing Carabao E-commerce Energy Drink!
                </div>
                <div>
                  <br />
                </div>
                <div>
                  Note: This Contact Us information is subject to change without
                  notice. Please check back for any updates.
                </div>
                <div>
                  <br />
                </div>
                <div>Last updated: January 17, 2024</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
        </div>
      </div> */}
      <section className="our_story_section" >
        <span className="light_left">
          {" "}
          <img src={about_page_lights_left} alt="about_page_lights_left" />{" "}
        </span>
        <span className="light_right">
          {" "}
          <img src={about_page_lights_left} alt="about_page_lights_left" />{" "}
        </span>
        <div className="container">
          <div className="our_story_box" style={{ marginBottom: '6%', marginTop:'1%' }}>
            <h3>Contact Us</h3>
            <p>
              If you have any questions, concerns, or feedback, we'd love to
              hear from you. Here's how you can reach us:
            </p>

            <div className="our_story_images_adjust pt-1 p-4">
              <ContactUs />
            </div>

            <p className="text-center pb-3">
              We value your feedback. Let us know how we can improve our
              products and services. Thank you for choosing Carabao E-commerce
              Energy Drink!.
            </p>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
}

export default ContactUsPage;
