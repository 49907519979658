import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ApiUrl } from "../../helpers/helpers";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    country: "Oman",
    companyName: "",
    interestedIn: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await axios.post(
        `${ApiUrl}contactUs`,
        {
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          country: formData.country,
          companyName: formData.companyName,
          interestedIn: formData.interestedIn,
          message: formData.message,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                name="email"
                value={formData.email}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                autoComplete="off"
                onChange={handleChange}
                minLength={10}
                maxLength={10}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <select
                className="form-select countrySelect"
                id="country"
                name="country"
                value={formData.country}
                autoComplete="off"
                onChange={handleChange}
                required
              >
                <option value="">Select Country</option>
                <option value="Oman">Oman</option>
                <option value="India">India</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                name="companyName"
                value={formData.companyName}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Interested in"
                name="interestedIn"
                value={formData.interestedIn}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 mt-5">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Message"
                name="message"
                value={formData.message}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-5">
            <div className="d-flex">
              <button
                type="submit"
                className="btn view_more_btn"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
                <Toaster />
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>} */}
    </div>
  );
}

export default ContactUs;
