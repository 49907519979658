import React, { useState, useEffect } from 'react';
import axios from "axios";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import step_check_icon from "../images/step_check_icon.svg";
import {ApiUrl,formatDate} from "../helpers/helpers";
import { Link } from "react-router-dom";

function ThankYouPage() {
  const [cartItems, setCartItems] = useState(null);
  const [apiResponce, setApiResponce] = useState(null);
  const [amount, setAmount] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    userAddress: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Retrieve selected product from local storage
    const first_name = localStorage.getItem("first_name");
    const email = localStorage.getItem("email");
    const mobileNumber = localStorage.getItem("mobileNumber");
    const userAddress = localStorage.getItem("userAddress");

    setFormData((prevFormData) => ({
      ...prevFormData,
      name: first_name,
      email: email,
      mobileNumber: mobileNumber,
      userAddress: userAddress,
    }));

    console.log(cartItems);
    

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token !== undefined && token !== "") {
          const response = await axios.get(`${ApiUrl}orderListToday`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
           console.log(response.data.data, "API response");
          setApiResponce(response.data.data);
          const storedCartItems = JSON.parse(response.data.data.cart);
          setCartItems(storedCartItems);
         setAmount(storedCartItems ? calculateTotal(storedCartItems) : 0);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };
    fetchData();


  }, []);
  // console.log(apiResponce, 'apiResponce');
  const calculateTotal = (cartItems) => {
    return cartItems.reduce((total, item) => {
      const stringitemPrice = item.price;
      const itemPrice = parseInt(stringitemPrice.replace(/[^\d.]/g, ""));

      const itemQuantity = parseInt(item.quantity);

      if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
        return total + itemPrice * itemQuantity;
      } else {
        console.error(`Invalid price or quantity for item ${item.productId}`);
        return total;
      }
    }, 0);
  };
  return (
    <>
      <Header></Header>
      <div className="grids">
        <div className="container">
          {/* <div className="top_step_navigation">
            <ul>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">
                    <img src={step_check_icon} />
                  </span>{" "}
                  shopping cart
                </a>
              </li>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">
                    <img src={step_check_icon} />
                  </span>{" "}
                  Check out{" "}
                </a>
              </li>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">
                    <img src={step_check_icon} />
                  </span>{" "}
                  Confirmation{" "}
                </a>
              </li>
            </ul>
          </div> */}
          <div className="page_content_box thank_you_page_content">
            <h1 className="page_title text-center mb-3">
              Thank you <span>for your purchase!</span>{" "}
            </h1>
            <p className="text-center subtitle">
              Your order will be processed within 24 hours during working days.
              We will notify you by email once your order has been shipped.{" "}
            </p>

            <div className="row flex-row-reverse">
              <div className="col-sm-12 col-md-12 col-lg-7 mt-3 mt-lg-5">
                <div className="sidebar_cover">
                  <h2 className="title_sidebar text-center">order summary</h2>

                  <ul className="purchase_heightlights">
                    <li>
                      <p>Date</p>
                      {apiResponce && <h4>{formatDate(apiResponce.created_at)}</h4>}
                    </li>
                    <li>
                      <p>order number</p>
                      {apiResponce && <h4>{apiResponce.order_id}</h4>}
                    </li>
                    <li>
                      <p>payment method</p>
                      <h4>master card</h4>
                    </li>
                  </ul>

                  {cartItems ? (
                    cartItems.map((item, index) => (
                      <div className="cart_product_box">
                        <div className="product_image pe-4">
                          {" "}
                          <img
                            src={item.image}
                            alt="cart_product_img"
                          />{" "}
                        </div>
                        <div className="product_descrition flex-grow-1">
                          <div className="product_name_with_buttons">
                            <h3 className="product_title">{item.name}</h3>

                            <div className="product_price_quantity">
                              <h4 className="product_price"> {item.price}</h4>
                              <div className="quantity-box">
                                {/* <button
                                  className="quantity-button"
                                  onclick="decreaseQuantity()"
                                >
                                  -
                                </button> */}
                                <input
                                  type="text"
                                  className="quantity-input"
                                  id="quantity"
                                  value={item.quantity}
                                />
                                {/* <button
                                  className="quantity-button"
                                  onclick="increaseQuantity()"
                                >
                                  +
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="noIteminCart">No Data Found</p>
                  )}
                  <div className="cart_page_summary_box pb-1 pt-4 pt-md-5 pt-lg-5">
                    <div className="table-responsive">
                      <table className="w-100">
                        <tbody>
                          {/* <tr>
                            <th>Delivery</th>
                            <td>Free</td>
                          </tr> */}
                          {/* <tr>
                            <th>Discount</th>
                            <td>-10%</td>
                          </tr> */}
                          <tr>
                            <th>tOTAL</th>
                            <td> ₹  {amount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-5 mt-0 mt-lg-5 pt-lg-5">
                <div className="login_info_box border-0">
                  <h3>Billing address</h3>
                  <div className="cart_page_summary_box pt-3 pt-lg-5 pb-0">
                    <div className="table-responsive">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <th>{formData.name}</th>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <th>
                            {formData.userAddress}
                            </th>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <th>  {formData.mobileNumber}</th>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <th>{formData.email}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="place_order_button_box mt-2 mt-lg-5 pt-lg-5">
                  <Link to={'/my-orders'} className="btn btn_placeorder">
                  My Orders
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default ThankYouPage;
