import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import step_check_icon from "../images/step_check_icon.svg";
import { ApiUrl } from "../helpers/helpers";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function CheckOut() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(null);
  const [amount, setAmount] = useState(0);
  const [pinCodeCity, setpinCodeCity] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    userAddress: "",
    pincode: "",
    city: "",
    state: "",
    country: "INDIA",
    addressType: "",
  });

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    localStorage.setItem(name, value);
    if (name === "pincode" && value.length === 6) {
      setpinCodeCity("");
      setLoading(true);

      try {
        const response = await axios.get(
          `${ApiUrl}verify/pincode?pincode=${value}`
        );
        console.log(response.data.delivery_codes, "pincode");
        let str = response.data.delivery_codes[0].postal_code.inc;
        const state = str.match(/\(([^)]+)\)/)[1];
        let Apicity = response.data.delivery_codes[0].postal_code.city;
        setpinCodeCity(Apicity);
        setFormData((prevFormData) => ({
          ...prevFormData,
          city: Apicity,
          state: state,
        }));
        localStorage.setItem("city", Apicity);
        localStorage.setItem("state", state);

        // setFormData('city':response.data.delivery_codes[0].postal_code.city);
      } catch (error) {
        console.error("Error fetching pincode data:", error);
        setpinCodeCity("Delivery not available for this pincode");
      } finally {
        setLoading(false); // Set loading state to false after receiving the response
      }
    }
  };

  const continueToNext = () => {
    // console.log(formData.mobileNumber, 'mobileNumber');
    // return;
    if (formData.userAddress === "") {
      toast.error("Please Fill Address");
    } else if (formData.name === "") {
      toast.error("Please Fill Name");
    } else if (formData.mobileNumber === null ) {
      toast.error("Please Fill Mobile Number");
    } else if (formData.userAddress === "") {
      toast.error("Please Fill Address");
    } else if (formData.pincode === "") {
      toast.error("Please Fill Pincode");
    } else if (formData.city === "") {
      toast.error("Please Fill city");
    } else if (formData.state === "") {
      toast.error("Please Fill state");
    } else if (formData.addressType === "") {
      toast.error("Please Fill Address Type");
    } else if (formData.userAddress !== "" && formData.mobileNumber !== null) {
      navigate("/confirmation");
    }
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    const first_name = localStorage.getItem("first_name");
    const email = localStorage.getItem("email");
    const mobileNumber = localStorage.getItem("mobileNumber");
    const userAddress = localStorage.getItem("userAddress");
    const pincode = localStorage.getItem("pincode");
    const city = localStorage.getItem("city");
    const state = localStorage.getItem("state");
    const addressType  = localStorage.getItem("addressType");

    setFormData((prevFormData) => ({
      ...prevFormData,
      name: first_name,
      email: email,
      mobileNumber: mobileNumber,
      userAddress: userAddress,
      pincode: pincode,
      city: city,
      state: state,
      addressType: addressType
      
    }));
    setCartItems(storedCartItems);
    setAmount(storedCartItems ? calculateTotal(storedCartItems) : 0);
  }, []);

  const calculateTotal = (cartItems) => {
    return cartItems.reduce((total, item) => {
      const stringitemPrice = item.price;
      const itemPrice = parseInt(stringitemPrice.replace(/[^\d.]/g, ""));

      const itemQuantity = parseInt(item.quantity);

      if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
        return total + itemPrice * itemQuantity;
      } else {
        console.error(`Invalid price or quantity for item ${item.productId}`);
        return total;
      }
    }, 0);
  };

  return (
    <>
      <Header />
      <div className="grids">
        <Toaster />
        <div className="container">
          <div className="top_step_navigation">
            <ul>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  <span className="step_count">
                    <img src={step_check_icon} alt="ww" />
                  </span>
                  shopping cart
                </a>
              </li>
              <li className="flex-grow-1 active">
                <a href="javascript:void(0)">
                  <span className="step_count">2</span> Check out
                </a>
              </li>
              <li className="flex-grow-1">
                <a href="javascript:void(0)">
                  <span className="step_count">3</span> Confirmation
                </a>
              </li>
            </ul>
          </div>
          <div className="page_content_box">
            <h1 className="page_title">
              Check <span>Out</span>
            </h1>

            <div className="row flex-row-reverse">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <div className="sidebar_cover">
                  <h2 className="title_sidebar">Your order</h2>
                  {cartItems ? (
                    cartItems.map((item, index) => (
                      <div className="cart_product_box" key={index}>
                        <div className="product_image pe-4">
                          <img src={item.image} alt="cart_product_img" />
                        </div>
                        <div className="product_descrition flex-grow-1">
                          <div className="product_name_with_buttons">
                            <h3 className="product_title">{item.name} </h3>
                            <div className="product_price_quantity">
                              <h4 className="product_price">{item.price}</h4>
                              <div className="quantity-box">
                                <button
                                  className="quantity-button"
                                  onClick={() => {
                                    if (item.quantity > 1) {
                                      const updatedCartItems = cartItems.map(
                                        (cartItem) => {
                                          if (
                                            cartItem.productId ===
                                            item.productId
                                          ) {
                                            return {
                                              ...cartItem,
                                              quantity: cartItem.quantity - 1,
                                            };
                                          }
                                          return cartItem;
                                        }
                                      );
                                      setCartItems(updatedCartItems);
                                      localStorage.setItem(
                                        "cartItems",
                                        JSON.stringify(updatedCartItems)
                                      );
                                    }
                                  }}
                                >
                                  -
                                </button>
                                <input
                                  type="text"
                                  className="quantity-input"
                                  id="quantity"
                                  value={item.quantity}
                                  readOnly
                                />
                                <button
                                  className="quantity-button"
                                  onClick={() => {
                                    const updatedCartItems = cartItems.map(
                                      (cartItem) => {
                                        if (
                                          cartItem.productId === item.productId
                                        ) {
                                          return {
                                            ...cartItem,
                                            quantity: cartItem.quantity + 1,
                                          };
                                        }
                                        return cartItem;
                                      }
                                    );
                                    setCartItems(updatedCartItems);
                                    localStorage.setItem(
                                      "cartItems",
                                      JSON.stringify(updatedCartItems)
                                    );
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="noIteminCart">No items in the cart</p>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="login_info_box">
                  <ul className="d-flex flex-wrap align-items-end">
                    <li className="flex-grow-1">
                      <input
                        type="text"
                        className="form-control custom-form-control"
                        placeholder="Name of the user"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </li>
                    {/* address pincode city state country addresstype */}
                    <li className="flex-grow-1">
                      <input
                        type="number"
                        className="form-control custom-form-control"
                        placeholder="MobileNumber"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li className="flex-grow-1">
                      <input
                        type="number"
                        className="form-control custom-form-control"
                        placeholder="Area Pincode"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleInputChange}
                      />

                      {/* <p>{pinCodeCity}</p> */}
                    </li>
                    <li className="flex-grow-1">
                      <input
                        type="text"
                        className="form-control custom-form-control"
                        placeholder="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li className="flex-grow-1">
                      <select
                        className="form-select classCustom"
                        id="addressType"
                        name="addressType"
                        value={formData.addressType}
                        autoComplete="off"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Address Type</option>
                        <option value="Home">Home</option>
                        <option value="Office">Office</option>
                      </select>
                    </li>

                    <li className="flex-grow-1">
                      <input
                        type="text"
                        className="form-control custom-form-control"
                        placeholder="state"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                      />
                    </li>

                   
                  </ul>
                </div>
                <div className="login_info_box">
                  <h3>Shipping address</h3>
                  <ul className="d-flex flex-wrap align-items-end">
                    <li className="flex-grow-1">
                      <textarea
                        type="text"
                        className="form-control custom-form-control"
                        // placeholder="Address"
                        name="userAddress"
                        value={formData.userAddress}
                        onChange={handleInputChange}
                        style={{ height: "112px" }}
                      />
                    </li>
                  </ul>
                </div>
                <div className="payment_via_debit">
                  <h3>
                    <li className="flex-grow-1">
                      {formData?.email ? (
                        <button
                          className="btn btn-change"
                          onClick={continueToNext}
                          disabled={
                            pinCodeCity ===
                            "Delivery not available for this pincode"
                          }
                        >
                          continue
                        </button>
                      ) : (
                        <Link to="/login">
                          <button className="btn btn-change">Login</button>
                        </Link>
                      )}
                    </li>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CheckOut;
