import React, { useState } from "react";
import axios from "axios";
import { loadScript, ApiUrl, generateRandomString  } from "../../helpers/helpers"; // Create a utility function to load the Razorpay script dynamically
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { resetCartCount } from '../../actions/ActionTypes'; // Import your action creators



const RazorpayButton = (data) => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function from React Redux

  const [loading, setLoading] = useState(false);



  const amountInPaise = data.amount * 100;
  const razorpayOptions = {
    key: "rzp_live_5h5kOqekvn61me", // Replace with your Razorpay API Key
    amount: amountInPaise, // Amount in paise (100 paise = 1 INR)
    currency: "INR",
    name: "CARABAO",
    description: "Product or service description",
    image: "https://www.carabaogroup.com/_nuxt/img/logo.ab05bad.png",
    handler: async function (response) {
      if (
        response.razorpay_payment_id !== undefined ||
        response.razorpay_payment_id !== ""
      ) {
          console.log(response, 'payment response');
      
        const cartItems = JSON.parse(localStorage.getItem("cartItems"));
        const mobileNumber = localStorage.getItem("mobileNumber");
        const userAddress = localStorage.getItem("userAddress");
        const pincode = localStorage.getItem("pincode");
        const city = localStorage.getItem("city");
        const state = localStorage.getItem("state");
        const addressType  = localStorage.getItem("addressType");
        const country = 'INDIA';
        const razorpay_payment_id = response.razorpay_payment_id;
        const token = localStorage.getItem("token");
        const amount = amountInPaise;
        const deliveryAmount = data.deliveryAmount;

       const order_id =  generateRandomString();
       console.log(order_id, 'order_id');
        //  return;
        
       
       let waybill =  await  getWayBill(token);
       const Apidata = {
        cartItems,
        razorpay_payment_id,
        mobileNumber,
        userAddress,
        amount,
        deliveryAmount,
        order_id,
        waybill,
        pincode,
        city,
        state,
        addressType,
        country
      };
      console.log(Apidata, 'Apidata');
        console.log(waybill, 'waybill');
      //  return;
        // return;
        try {
          const response = await axios.post(`${ApiUrl}placeOrder`, Apidata, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          // Handle the response as needed
          console.log(response.data);
        } catch (error) {
          // Handle errors
          console.error("Error placing order:", error);
        }
      }
      dispatch(resetCartCount());
      localStorage.removeItem("cartItems");
      navigate('/thank-you');
    },
    prefill: {
      name: data.name,
      email: data.email,
      contact: data.contact,
    },
    notes: {
      address: data.address,
    },
    theme: {
      color: "#00D80F",
    },
    modal: {
      onDismiss: function() {
        setLoading(false); // Reset loading state if the dialog is dismissed
      },
    },
  };

  const handlePayment = async () => {
    setLoading(true); // Set loading to true while payment processing
    const Razorpay = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const rzp = new Razorpay(razorpayOptions);
    rzp.on('payment.failed', function (response){
      setLoading(false); // Set loading to false if payment fails
    });
    rzp.on('payment.cancel', function (response){
      setLoading(false); // Set loading to false if payment dialog is canceled
    });
    rzp.open();
  };

  const getWayBill = async(token) =>{
      const response = await axios.get(`${ApiUrl}waybill?count=1`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

     return  response.data.data;
  }

  return (
    <div>
      {loading ? (
        <button className="btn btn_pay_now">
        Loading...
        </button>
      ) : (
        <button className="btn btn_pay_now" onClick={handlePayment}>
          Place Order
        </button>
      )}
      <Toaster />
    </div>
  );
};

export default RazorpayButton;
