import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { incrementCartCount, decrementCartCount } from '../actions/ActionTypes'; // Import your action creators

import axios from "axios";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import { Link } from "react-router-dom";
import { ApiUrl, imageUrl } from "../helpers/helpers";

const CartPage = () => {
  const dispatch = useDispatch(); // Get the dispatch function from React Redux

  const [cartItems, setCartItems] = useState(null);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    setCartItems(storedCartItems);
  }, []);

  const handleQuantityChange = (productId, newQuantity) => {
    if (newQuantity < 1) {
      newQuantity = 1; 
    }
  
    const updatedCartItems = cartItems.map(item => {
      if (item.productId === productId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const handleDelete = (productId) => {
    const updatedCartItems = cartItems.filter(item => item.productId !== productId);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    dispatch(decrementCartCount());
  };

  const calculateTotal = (items) => {
    return items.reduce((total, item) => {
      const stringItemPrice = item.price;
      const itemPrice = parseInt(stringItemPrice.replace(/[^\d.]/g, ""), 10);
      const itemQuantity = parseInt(item.quantity, 10);

      if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
        return total + itemPrice * itemQuantity;
      } else {
        console.error(`Invalid price or quantity for item ${item.productId}`);
        return total;
      }
    }, 0);
  };


  return (
    <>
      <Header></Header>
      <div className="grids">
        <div className="container">
          <div className="top_step_navigation">
            <ul>
              <li className="flex-grow-1 active">
                <a href="javascript:void(0)">
                  <span className="step_count">1</span> shopping cart
                </a>
              </li>
              <li className="flex-grow-1">
                <a href="javascript:void(0)">
                  <span className="step_count">2</span> Check out
                </a>
              </li>
              <li className="flex-grow-1">
                <a href="javascript:void(0)">
                  <span className="step_count">3</span> Confirmation
                </a>
              </li>
            </ul>
          </div>
          <div className="page_content_box">
            <h1 className="page_title">
              Shopping <span>Cart</span>
            </h1>
            <div className="cart_products_cover">
              {cartItems ? (
                cartItems.map((item, index) => (
                  <div className="cart_product_box" key={index}>
                    <div className="product_image pe-4 pb-3">
                      <img src={item.image} alt="cart_product_img" />
                    </div>
                    <div className="product_descrition flex-grow-1">
                      <div className="product_name_with_buttons">
                        <h3 className="product_title">{item.name}</h3>
                        <ul className="d-flex align-items-center flex-wrap">
                          {/* <li>
                            <a className="add_to_wishlist" href="#">
                              Add to wishlist
                            </a>
                          </li> */}
                          <li>
                            <a className="delete_from_cart" href="#" onClick={() => handleDelete(item.productId)}>
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="product_price_quantity">
                        <h4 className="product_price"> {item.price}</h4>
                        <div className="quantity-box">
                          <button className="quantity-button" onClick={() => handleQuantityChange(item.productId, item.quantity - 1)}>
                            -
                          </button>
                          <input
                            type="text"
                            className="quantity-input"
                            id="quantity"
                            value={item.quantity}
                            readOnly
                          />
                          <button className="quantity-button" onClick={() => handleQuantityChange(item.productId, item.quantity + 1)}>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="noIteminCart">No items in the cart</p>
              )}
            </div>

            <div className="cart_page_summary_box">
              <h2 className="main-title">SUMMARY</h2>

              <div className="table-responsive">
                <table className="w-100">
                  <tr>
                    <th>TOTAL</th>
                    <td> ₹  {cartItems ? calculateTotal(cartItems) : 0}</td>
                  </tr>
                  {/* <tr>
                    <th>SERVICE TAX</th>
                    <td> ₹  140.00</td>
                  </tr>
                  <tr>
                    <th>SHIPPING CHARGES</th>
                    <td> ₹  0</td>
                  </tr> */}
                </table>
              </div>
            </div>

            <div className="text-center checkout_page_btn">
  {cartItems && cartItems.length > 0 ? (
    <Link className="btn" to="/check-out">
      CHECK OUT
    </Link>
  ) : (
    <Link className="btn" 
    
    >
    Your cart is empty
    </Link>
  )}
</div>

          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CartPage;
