import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import step_check_icon from "../images/step_check_icon.svg";
import RazorpayButton from "./components/RazorpayButton";
import axios from "axios";
import { ApiUrl } from "../helpers/helpers";



function ConfirmationPage() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(null);
  const [amount, setAmount] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    userAddress: "",
  });
  const [loading, setLoading] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false); // Track checkbox state

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Retrieve selected product from local storage
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    const first_name = localStorage.getItem("first_name");
    const email = localStorage.getItem("email");
    const mobileNumber = localStorage.getItem("mobileNumber");
    const userAddress = localStorage.getItem("userAddress");
    const pincode = localStorage.getItem("pincode");
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: first_name,
      email: email,
      mobileNumber: mobileNumber,
      userAddress: userAddress,
      pincode: pincode,
    }));
    console.log(cartItems);
    setCartItems(storedCartItems);
    setAmount(storedCartItems ? calculateTotal(storedCartItems) : 0);

    CalculateShippingCost(pincode);
    // Calculate total when cart items change
  }, []);

  const CalculateShippingCost = async (pincode) => {

    setLoading(true);
    try {

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${ApiUrl}calculateShippingCost?md=S&ss=Delivered&d_pin=${pincode}&o_pin=122002&cgm=1000&pt=Pre-paid&cod=0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDeliveryAmount(response.data.data[0].total_amount)
      console.log(response.data.data[0].total_amount, 'response');
    } catch (error) {
      console.error("Error fetching pincode data:", error);

    } finally {
      setLoading(false); // Set loading state to false after receiving the response
    }
  }

  const calculateTotal = (cartItems) => {
    return cartItems.reduce((total, item) => {
      const stringitemPrice = item.price;
      const itemPrice = parseInt(stringitemPrice.replace(/[^\d.]/g, ""));

      const itemQuantity = parseInt(item.quantity);

      if (!isNaN(itemPrice) && !isNaN(itemQuantity)) {
        return total + itemPrice * itemQuantity;
      } else {
        console.error(`Invalid price or quantity for item ${item.productId}`);
        return total;
      }
    }, 0);
  };

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleOrderPlacement = () => {
    if (!termsChecked) {
      toast.error("Please accept the Terms & Conditions to proceed.");
    } else {
      // Proceed with order placement
      // For example, redirect to checkout page
      navigate("/checkout");
    }
  };

  return (
    <>
      <Header />
      <div className="grids">
        <Toaster />
        <div className="container">
          <div className="top_step_navigation">
            <ul>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">
                    <img src={step_check_icon} />
                  </span>{" "}
                  shopping cart
                </a>
              </li>
              <li className="flex-grow-1 active success_step">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">
                    <img src={step_check_icon} />
                  </span>{" "}
                  Check out{" "}
                </a>
              </li>
              <li className="flex-grow-1 active">
                <a href="javascript:void(0)">
                  {" "}
                  <span className="step_count">3</span> Confirmation
                </a>
              </li>
            </ul>
          </div>
          <div className="page_content_box confirmation_page_ui">
            <h1 className="page_title">
              order <span>confirmation</span>{" "}
            </h1>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="login_info_box h-100">
                  <ul className="d-flex align-items-start">
                    <li className="flex-grow-1">
                      <h3 className="w-100">Your information</h3>
                      <div className="mt-4 mb-3">
                        <p>Name: {formData.name}</p>
                        <p></p>
                        <a href={`mailto:${formData.email}`}>Email: {formData.email}</a>
                        <p></p>
                        <a href={`mailto:${formData.mobileNumber}`}>M.no: {formData.mobileNumber}</a>
                      </div>
                    </li>
                    {/* <li>
                      <button className="btn btn-change">Edit</button>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="login_info_box h-100">
                  <ul className="d-flex align-items-start">
                    <li className="flex-grow-1">
                      <h3 className="w-100">Shipping address</h3>
                      <div className="mt-4 mb-3">
                        <p>{formData.userAddress}</p>
                        <p>Area Pin Code {formData.pincode}</p>
                      </div>
                    </li>
                    <li>
                      <Link to="/check-out">
                        <button className="btn btn-change">Edit</button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="terma_condition_box mt-4 mb-3">
              <div className="remember_password_box">
                <input
                  type="checkbox"
                  className="custom_checkbox"
                  onChange={handleCheckboxChange}
                />
                <label>Terms & Conditions</label>
              </div>
            </div>

            {/* Error message for unchecked terms */}
            {!termsChecked && (
              <p className="text-danger">
                Please accept the Terms & Conditions to proceed.
              </p>
            )}

            <div className="tottal_order_box">
              <div className="subtottal_ammount">
                <p>Order total:</p>
                <h3 className="sub_total_amount"> ₹  {amount}</h3>
              </div>


              <div className="subtottal_ammount">
                <p>Delivery Charges:</p>
                <h3 className="sub_total_amount"> ₹  {deliveryAmount}</h3>
              </div>

              <div className="subtottal_ammount">
                <p>SubTotal:</p>
                <h3 className="sub_total_amount"> ₹  {amount+ deliveryAmount}</h3>
              </div>

              <div className="place_order_button_box">
                {termsChecked ? (
                  <RazorpayButton
                    amount={amount+deliveryAmount}
                    name={formData.name}
                    contact={formData.mobileNumber}
                    address={formData.userAddress}
                    deliveryAmount = {deliveryAmount}
                  />
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={handleOrderPlacement}
                  >
                    PLACE ORDER
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ConfirmationPage;
