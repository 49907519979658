import "../css/style.css";
import main_product_text_bigger_img from "../images/main_product_text_bigger_img.svg";
import about_page_lights_left from "../images/about_page_lights_left.png";
import footer_layer_img from "../images/footer_layer_img.png";
import our_story_bottle_img from "../images/our_story_bottle_img.png";
import champion_cup_img from "../images/champion_cup_img.png";
import carabao_cup_big_image from "../images/carabao_cup_big_image.png";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
function AboutUsPage() {
  return (
    <>
      <Header navigateRoute={'about-us'}></Header>
      <section className="product_page_banner about_us_banner_section">
        <span className="main_product_text_bigger_img">
          <img
            src={main_product_text_bigger_img}
            alt="main_product_text_bigger_img"
          />{" "}
        </span>
      </section>
      <section className="our_story_section">
        <span className="light_left">
          {" "}
          <img
            src={about_page_lights_left}
            alt="about_page_lights_left"
          />{" "}
        </span>
        <span className="light_right">
          {" "}
          <img
            src={about_page_lights_left}
            alt="about_page_lights_left"
          />{" "}
        </span>
        <div className="container">
          <div className="our_story_box">
            <h3>our Story</h3>
            <p>
              In 2002, Aed Carabao, the lead singer of Thailand’s most
              influential and successful Carabao band and his industrialist
              friend Sathien Setthasit co-founded the Carabao energy drink
              brand. The Carabao brand has reached the world! The sales network
              covers all continents, more than 40 countries and regions. The
              Carabao brand gives everyone the spirit and strength to overcome
              challenges, fills everyone with fighting spirit, challenges the
              impossible in life, and keeps moving toward the goal!{" "}
            </p>

            <p>
              Arneja Foods is a leading food distribution company based in the
              vibrant state of Punjab, India. Our company takes pride in
              embodying the culinary legacy and hospitality that Punjab’s are
              renowned for with a passion for serving quality food, we have
              ventured into the food distribution industry, establishing
              ourselves as a trusted name in the market. we have been committed
              to partnering with esteemed food brands to create a strong
              presence in the North Indian market.
            </p>

            <div className="our_story_images_adjust">
              <div className="zigzag_img">
                {" "}
                <img src={footer_layer_img} alt="footer_layer_img" />
              </div>
              <div className="d-flex">
                <div className="out_story_product_img">
                  {" "}
                  <img
                    src={our_story_bottle_img}
                    alt="our_story_bottle_img"
                  />{" "}
                </div>
                <div className="champion_cup">
                  {" "}
                  <img
                    src={champion_cup_img}
                    alt="champion_cup_img"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="carabao_cup_section">
        <div className="container-fluid">
          <div className="text-center">
            <h5>We are Title Sponsor</h5>
            <h2>Carabao Cup</h2>
            <p>
              We love football! That's why we're the proud sponsor of the
              Carabao Cup. Find out more about our football partnerships{" "}
            </p>
          </div>
          <div className="featured_img">
            <img
              src={carabao_cup_big_image}
              alt="carabao_cup_big_image"
            />
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default AboutUsPage;
