export const INCREMENT_CART_COUNT = 'INCREMENT_CART_COUNT';
export const DECREMENT_CART_COUNT = 'DECREMENT_CART_COUNT';
export const RESET_CART_COUNT = 'RESET_CART_COUNT';



export const incrementCartCount = () => ({
    type: INCREMENT_CART_COUNT
  });
  
  export const decrementCartCount = () => ({
    type: DECREMENT_CART_COUNT
  });

  export const resetCartCount = () => {
    return {
      type: RESET_CART_COUNT
    };
  };