// src/actions/cartActions.js
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: product
});

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: productId
});

export const INCREMENT_CART_COUNT = 'INCREMENT_CART_COUNT';
export const DECREMENT_CART_COUNT = 'DECREMENT_CART_COUNT';

