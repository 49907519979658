import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../css/style.css";
import freshing_green_left_light from "../images/freshing_green_left_light.png";
import fuel_product_one from "../images/fuel_product_one.png";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import ProductList from "./components/ProductsComponent";
import Slider from "react-slick";
import { ApiUrl, imageUrl } from "../helpers/helpers";
import loader from "../images/loader.gif";
import QuantitySelector from "./components/QuantitySelector";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";


function ProductDetails() {
  const [productInfo, setProductInfo] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const apiUrl = `${ApiUrl}product/info/${slug}`;
    axios
      .get(apiUrl)
      .then((response) => {
        setProductInfo(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching product info:", error);
      });
  }, [slug]);

  console.log(productInfo, "slug");

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Header navigateRoute={"product"}></Header>
      <div className="grids product_detail_page_grids">
        <span className="freshing_green_light_left">
          {" "}
          <img
            src={freshing_green_left_light}
            alt="freshing_green_left_light"
          />{" "}
        </span>

        <span className="freshing_green_light_right">
          {" "}
          <img
            src={freshing_green_left_light}
            alt="freshing_green_left_light"
          />{" "}
        </span>
        <div className="container-fluid">
          <div className="product_detail_page_box">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                <div className="product_img_slider_box">
                  <div className="product-slider-for text-center">
                    <div>
                      {" "}
                      {productInfo ? (
                        <img
                          src={`${imageUrl}${productInfo.photo}`}
                          alt="fuel_product_one"
                        />
                      ) : (
                        <div className="LoderSpinnerDiv">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="product-slider-nav">
                    <Slider {...settings}>
                      <div className="spacing">
                        {" "}
                        <img
                          src={fuel_product_one}
                          alt="fuel_product_one"
                        />{" "}
                      </div>
                      <div className="spacing">
                        {" "}
                        <img
                          src={fuel_product_one}
                          alt="fuel_product_one"
                        />{" "}
                      </div>
                      <div className="spacing">
                        {" "}
                        <img
                          src={fuel_product_one}
                          alt="fuel_product_one"
                        />{" "}
                      </div>
                      <div className="spacing">
                        {" "}
                        <img
                          src={fuel_product_one}
                          alt="fuel_product_one"
                        />{" "}
                      </div>
                      <div className="spacing">
                        {" "}
                        <img
                          src={fuel_product_one}
                          alt="fuel_product_one"
                        />{" "}
                      </div>
                    </Slider>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7">
                <div className="product_page_detail_description">
                  <h3 className="product_title">
                    {productInfo ? <>{productInfo.name}</> : <></>}
                  </h3>

                  <h4 className="product_price">
                    <span>
                      {productInfo ? <>{productInfo.discount_price}</> : <></>}
                    </span>
                  </h4>

                  <p>
                    {productInfo ? (
                      <>
                        <div
                          className="productDesc"
                          dangerouslySetInnerHTML={{
                            __html: productInfo.details,
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </p>

                  <QuantitySelector
                    productId={productInfo ? productInfo.id : ""}
                    name={productInfo ? productInfo.name : ""}
                    image={productInfo ? imageUrl + productInfo.photo : ""}
                    price={productInfo ? productInfo.discount_price : ""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="project_full_description">
                  <h3 className="product_title product_title_border">
                    Product’s Description
                  </h3>

                  <p>
                    {productInfo ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productInfo.details,
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </p>

                  <p>
                    {" "}
                    In 2002, Aed Carabao, the lead singer of Thailand’s most
                    influential and successful Carabao band and his
                    industrialist friend Sathien Setthasit co-founded the
                    Carabao energy drink brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="more_fuel_for_file_sec product_more_fuel_sec mb-5 pb-4">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-center align-items-center px-3 pb-3">
              <h3 className="main_title">MORE FUEL FOR YOUR FIRE</h3>
            </div>

            <div className="px-3">
              <div className="cans_products_slider">
                <ProductList></ProductList>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Link to={"/product"}>
                <button className="btn explore_more_btn">View More</button>{" "}
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
}

export default ProductDetails;
