import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { fetchProducts } from "../../actions/productActions";
import { addToCart } from "../../actions/cartActions";
import { ApiUrl } from "../../helpers/helpers";
import { imageUrl } from "../../helpers/helpers";
import "../../css/style.css";
import { Link } from "react-router-dom";
import AddToCartButtonSingle from "./AddToCartButtonSingle";
import Spinner from "react-bootstrap/Spinner";

const MerchantProducts = ({
  products,
  loading,
  error,
  fetchProducts,
  addToCart,
}) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page]); // Fetch data whenever page changes

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token !== undefined && token !== "") {
        const response = await axios.get(
          `${ApiUrl}products/list/merchant?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data, "API response");
        if (page === 1) {
          setApiResponse(response.data.data);
        } else {
          setApiResponse((prevData) => ({
            ...prevData,
            data: [...prevData.data, ...response.data.data.data],
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleViewMore = () => {
    setLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
  };

  if (loading || !apiResponse) {
    return<>
      <div className="LoderSpinnerDiv">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <div className="px-3">
        <div className="cans_products_slider">
          <div className="row">
            {apiResponse.data.map((product) => (
              <div
                key={product.id}
                className="col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mb-4"
              >
                <div className="product_box">
                  <div className="product_img">
                    <Link
                      className="nav-link"
                      to={`/product-details/${product.slug}`}
                    >
                      <img
                        src={product ? imageUrl + product.photo : ""}
                        className="ListImageProduct"
                        alt="fuel_product_one"
                      />
                      <a href="#" className="add_to_wishlist"></a>
                    </Link>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="product_name_with_quantity">
                      <Link
                        className="nav-link"
                        to={`/product-details/${product.slug}`}
                      >
                        <h4 className="product_name">{product.name}</h4>
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between pt-3 pb-2">
                    <div className="product_price">
                      <span className="sale_price">
                        {product.discount_price}
                      </span>
                    </div>
                    <div className="add_to_cart_btn_div">
                      <AddToCartButtonSingle
                        productId={product ? product.id : ""}
                        name={product ? product.name : ""}
                        image={product ? imageUrl + product.photo : ""}
                        price={product ? product.discount_price : ""}
                        quantity={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4">
        {apiResponse.next_page_url && (
          <button
            className="btn explore_more_btn"
            onClick={handleViewMore}
            disabled={loadingMore}
          >
            {loadingMore ? "Loading..." : "View More"}
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.products,
  loading: state.products.loading,
  error: state.products.error,
});

export default connect(mapStateToProps, { fetchProducts, addToCart })(
    MerchantProducts
);
