import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import "../css/style.css";
import website_logo from "../images/website_logo.svg";
import search_icon from "../images/search_icon.svg";
import cart_icon from "../images/cart_icon.svg";
import my_account_icon from "../images/my_account_icon.svg";
import { Link} from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


function Header() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setuserEmail] = useState(false);
  const [userName, setuserName] = useState(false);
  const cartCount = useSelector(state => state.cart.count);





  useEffect(() => {
    const email = localStorage.getItem("email");
    const first_name = localStorage.getItem("first_name");

    
    if (email) {
      setuserEmail(email);
      setuserName(first_name);

      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      localStorage.clear();
      setLoggedIn(false);
      navigate('/');
     
    }
  };

  return (
    <div className="header_banner_cover">
      <div className="topbar">
        <div className="marquee-section">
        <div className="website-marquee">
            <marquee className="li" direction="right">
              <ul>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
                <li>
                  <p>
                    <b>15%</b> Off on Entire website on your first order -
                  </p>
                </li>
              </ul>
            </marquee>
          </div>
        </div>
      </div>
      <header className="website-header">
        <div className="container-fluid">
          <div className="main-menu align-items-center">
            <Link to="/" className="brand">
              <img src={website_logo} alt="website_logo" />
            </Link>
            <div className="navbar-div-with-language-btn">
              <div className="menu-items">
                <Navbar expand="lg" className="p-0">
                  <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" className="nav-link">
                          Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/about-us" className="nav-link">
                          About Us
                        </Nav.Link>
                        <Nav.Link as={Link} to="/product" className="nav-link">
                          Products
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/distributors"
                          className="nav-link"
                        >
                          Distributors
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/influencer"
                          className="nav-link"
                        >
                          Influencer
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/merchandise"
                          className="nav-link"
                        >
                          merchandise
                        </Nav.Link>
                        
                        <Nav.Link
                          as={Link}
                          to="/contact-us"
                          className="nav-link"
                        >
                          Contact Us
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
            </div>
            <div className="quick_action_links">
              <ul className="d-flex align-items-center">
                {/* <li>
                  <Link href="#">
                    <img src={search_icon} alt="search_icon" />
                  </Link>
                </li> */}
                <li>
                  <Link to="/cart">
                    <img src={cart_icon} alt="cart_icon" />
                    <span>{cartCount}</span>
                  </Link>
                </li>
                {loggedIn ? (
                  <>
                    <li>
                      <NavDropdown className="logoutDropDownMain" title={<img src={my_account_icon} alt="my_account_icon" />} id="basic-nav-dropdown">
                      <NavDropdown.Item >{userName}</NavDropdown.Item>
                      
                        <NavDropdown.Item onClick={handleLogout} className="logoutDropDown">Logout</NavDropdown.Item>    
                      </NavDropdown>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link to="/login">
                      <img src={my_account_icon} alt="my_account_icon" />
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
