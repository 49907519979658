import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import influencer_banner_background from "../images/influencer_banner_background.png";
import influencer_flash_left from "../images/influencer_flash_left.png";
import influencer_flash_right from "../images/influencer_flash_right.png";
import heightlight_light from "../images/heightlight_light.png";
import ContactUs from "./components/ContactUs";


function DistributorsPage() {
  return (
    <>
      <Header navigateRoute={'influencer'}></Header>
      <section className="product_page_banner about_us_banner_section influencer_banenr_sec">
        <span className="influencer_flash_left">
          {" "}
          <img src={influencer_flash_left} alt="1" />{" "}
        </span>
        <span className="influencer_flash_right">
          {" "}
          <img src={influencer_flash_right} alt="1" />{" "}
        </span>
        <span className="main_product_text_bigger_img">
          <img
            src={influencer_banner_background}
            alt="influencer_banner_background"
          />{" "}
        </span>
      </section>
      <div className="grids">
        <section className="welcome_influencer_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <span className="sub_heading_text">Welcome to Carabao</span>
                <h2>Where Influencers Thrive!</h2>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="description">
                  <p>
                    {" "}
                    Are you a passionate content creator with a knack for making
                    waves on social media? Do you have a unique voice and the
                    ability to captivate your audience? If so, we invite you to
                    join our exclusive community of influencers at Carabao{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="influencer_why_carabao_sec">
          <span className="light_right">
            {" "}
            <img
              src={heightlight_light}
              alt="heightlight_light"
            />{" "}
          </span>

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="main_title">Why Carabao ?</h2>

                <ul className="reasons_why_carabao_list">
                  <li>
                    <h2>Unparalleled Exposure</h2>
                  </li>
                  <li>
                    <h2>Collaboration Opportunities</h2>
                  </li>
                  <li>
                    <h2>Robust Analytics</h2>
                  </li>
                  <li>
                    <h2>Supportive Community</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="influencer_how_to_join_sec">
          <div className="container">
            <div className="influencer_form">
              <div className="header_div">
                <div>
                  <h2>How to Join</h2>{" "}
                </div>
                <div>
                  <p>
                    <span className="d-block">
                      <b>Ready to take your influence to the next level ?</b>
                    </span>
                    Joining Carabao is easy. Simply fill out the form below to
                    get started on your journey to becoming a part of our
                    exclusive influencer community.
                  </p>
                </div>
              </div>
              <ContactUs/>
            </div>
          </div>
        </section>

        <section className="influencer_our_requirements_sec">
          <div className="container">
            <div className="influencer_requirement_row">
              <div className="main-title">Our Requirements</div>
              <div className="requirement_list">
                <ul className="requirement_list_box">
                  <li>
                    Active presence on at least one major social media platform
                  </li>
                  <li>Should have minimum 10k of following</li>
                  <li>Engaged and authentic audience</li>
                  <li>Passion for creating compelling content</li>
                  <li>
                    Commitment to maintaining a positive and respectful online
                    presence{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="become_trail_brezzer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="become_trail_brezzer_box">
                <h3 className="main-title">
                  {" "}
                  Become a Trailblazer with <span>Carabao!</span>
                </h3>
                <p>
                  at Carabao, we believe in empowering influencers to reach new
                  heights. Join us today and become part of a community that
                  values creativity, authenticity, and the power of influence.
                  Let's create something extraordinary together!
                </p>
                <button className="btn join_now_btn">Join Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default DistributorsPage;
