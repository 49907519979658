import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import "./App.css";

import FrontPage from "./pages/FrontPage";
import AboutUsPage from "./pages/AboutUsPage";
import ProductPage from "./pages/ProductPage";
import DistributorsPage from "./pages/DistributorsPage";
import InfluencerPage from "./pages/InfluencerPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import CartPage from "./pages/CartPage";
import CheckOut from "./pages/CheckOutPage";
import ThankYouPage from "./pages/ThankYouPage";
import ProductDetails from './pages/ProductDetails';
import  ConfirmationPage from './pages/ConfirmationPage';
import MyOrders from './pages/OrderPage';
import OrderInfo from './pages/OrderInfo';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsandConditionsPage';
import CancellationRefundPolicyPage from './pages/CancellationRefundPolicyPage';
import ShippingDeliveryPolicyPage from './pages/CancellationRefundPolicyPage';
import ContactUsPage from './pages/ContactUsPage';
import MerchandisePage from './pages/MerchandisePage';
import { Provider } from 'react-redux';
import store from './store';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/product-details/:slug" element={<ProductDetails />} />
            <Route path="/distributors" element={<DistributorsPage />} />
            <Route path="/influencer" element={<InfluencerPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/cart"  element={<CartPage />} />
            <Route path="/check-out"  element={<CheckOut />} />
            <Route path="/thank-you"  element={<ThankYouPage />} />
            <Route path="/confirmation"  element={<ConfirmationPage />} />
            <Route path="/my-orders"  element={<MyOrders />} />
            <Route path="/orderInfo/:id"  element={<OrderInfo />} />
            <Route path="/privacy-policy"  element={<PrivacyPolicyPage />} />  
            <Route path="/terms-and-conditions"  element={<TermsAndConditionsPage/>} />  
            <Route path="/cancellation-refund-policy"  element={<CancellationRefundPolicyPage/>} />  
            <Route path="/shipping-delivery-policy"  element={<ShippingDeliveryPolicyPage/>} />  
            <Route path="/contact-us"  element={<ContactUsPage/>} />  
            <Route path="/merchandise"  element={<MerchandisePage/>} />  
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
