import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import distributors_banner_background from "../images/distributors_banner_background.jpg";
import about_page_lights_left from "../images/about_page_lights_left.png";
import float_bottle_img from "../images/float_bottle_img.png";
import carabao_cup_tag from "../images/carabao_cup_tag.png";
import ContactUs from "./components/ContactUs";

function DistributorsPage() {
  return (
    <>
      <Header navigateRoute={'distributors'}></Header>
      <section className="product_page_banner about_us_banner_section distribution_sec_banner">
    <span className="main_product_text_bigger_img"><img src={distributors_banner_background}
        alt="main_product_text_bigger_img"/> </span>
 

  </section>
  <section className="our_story_section join_our_winning_team_section">
    <span className="light_left"> <img src={about_page_lights_left} alt="about_page_lights_left"/> </span>
    <span className="light_right"> <img src={about_page_lights_left} alt="about_page_lights_left"/> </span>

    <div className="container">

    <div className="contact_us_form">
      <span className="floating_bottle"><img src={float_bottle_img} alt="float_bottle_img" /></span>
      <span className="floating_bottle right_bottle"><img src={float_bottle_img} alt="float_bottle_img"/></span>
      <span className="floating_carabao_cup_tag"><img src={carabao_cup_tag} alt="carabao_cup_tag"/></span>
      <div className="contact_heading">
        <h5>WANT TO SELL CARABAO ?</h5>
        <h2>JOIN OUR WINNING TEAM!</h2>
        <p>Carabao is the UK's fastest growing energy drink and we want you to be part of our winning team!</p>
      </div>
      <div className="trade_enquery_heading">
        <h6>Contact us by Completing the form below</h6>
        <h4>TRADE ENQUIRY</h4>
      </div>
      <ContactUs/>
    </div>

</div>
  
  </section>
      <Footer></Footer>
    </>
  );
}

export default DistributorsPage;
