import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import { ApiUrl, formatDate } from "../helpers/helpers";
import place_orders_icon from "../images/place_orders_icon.svg";
import order_confirm_icon from "../images/order_confirm_icon.svg";
import order_processed_icon from "../images/order_processed_icon.svg";
import ready_to_pickup_icon from "../images/ready_to_pickup_icon.svg";
import copy from "../images/copy.png";
import done from "../images/done.png";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";

function OrderInfo() {
  const [OrderInfo, setOrderInfo] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false); // State to track copy success
  const { id } = useParams();
  console.log(id, "id");

  useEffect(() => {
    const apiUrl = `${ApiUrl}orderInfo?id=${id}`;
    const token = localStorage.getItem("token");
    if (token !== undefined && token !== "") {
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setOrderInfo(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching product info:", error);
        });
    }
  }, [id]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopySuccess(true); // Set copySuccess to true on successful copy
        setTimeout(() => setCopySuccess(false), 2000); // Reset copySuccess after 2 seconds
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };
  console.log(OrderInfo);

  return (
    <>
      <Header></Header>
      <div className="grids">
        <div className="container pt-4 mt-3">
          <div className="page_content_box my_orders_page_ui">
            <h1 className="page_title text-start mb-3">
              Track <span>Orders</span>{" "}
            </h1>
            <div className="row flex-row-reverse">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-12">
                <div className="sidebar_cover ms-0 mb-4 mt-0 ">
                  <h2 className="title_sidebar mb-0">order summary</h2>
                  {OrderInfo ? (
                    <>
                      <ul className="purchase_heightlights">
                        <li className="pt-4 mt-3">
                          <p>Date</p>
                          <h4>
                            {OrderInfo ? (
                              <>{formatDate(OrderInfo.created_at)}</>
                            ) : (
                              <>Fetching</>
                            )}
                          </h4>
                        </li>
                        {/* <li className="pt-4 mt-3">
      <p>Product</p>
      <h4>Name of Product</h4>
    </li> */}
                        <li className="pt-4 mt-3">
                          <p>order number</p>
                          <h4>
                            {" "}
                            {OrderInfo ? (
                              <>{OrderInfo.order_id}</>
                            ) : (
                              <>Fetching</>
                            )}
                          </h4>
                        </li>
                        <li className="pt-4 mt-3">
                          <p>payment Id</p>
                          <h4>
                            {OrderInfo ? (
                              <>{OrderInfo.transaction_number}</>
                            ) : (
                              <></>
                            )}
                          </h4>
                        </li>

                        <li className="pt-4 mt-3">
                          <p>Amount</p>
                          <h4>
                            {OrderInfo ? (
                              <>₹ {OrderInfo.state_price / 100}</>
                            ) : (
                              <></>
                            )}
                          </h4>
                        </li>
                        <li className="pt-4 mt-3">
                          <p>Order Status </p>
                          <h4>
                            {OrderInfo ? <> {OrderInfo.order_status}</> : <></>}
                          </h4>
                        </li>
                        <li className="pt-4 mt-3">
                          <p>Order Way bill / Track NO</p>
                          <h4>
                            {OrderInfo ? <> {OrderInfo.waybill}</> : <></>}
                            <button
                                  onClick={() =>
                                    copyToClipboard(OrderInfo.waybill)
                                  }
                                  style={{ backgroundColor:'transparent', border: 'none' }}
                                >
                                  <img src={copy} alt="Copy" style={{ width:'29px' }} />{" "}
                                  {copySuccess ? (
                                    <img src={done} alt="Copied" style={{ width:'29px' }} />
                                  ) : (
                                    ""
                                  )}
                                </button>
                          </h4>
                        </li>
                      </ul>
                      <div className="tracking_slider_ui">
                        <ul className="d-flex justify-content-between">
                          <li>
                            <div className="icon_with_time">
                              <span className="icon">
                                <img src={place_orders_icon} alt="" />
                              </span>
                              <h6 className="date_text">
                                {OrderInfo ? (
                                  <>{formatDate(OrderInfo.created_at)}</>
                                ) : (
                                  <>Fetching</>
                                )}
                                <span> 04:11 AM</span>
                              </h6>
                            </div>
                            <div className="progress_comment">
                              <h2>PLACE ORDER</h2>
                              <p>We have receive your order.</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon_with_time">
                              <span className="icon">
                                <img src={order_confirm_icon} />
                              </span>
                              <h6 className="date_text">
                                {OrderInfo ? (
                                  <>{formatDate(OrderInfo.created_at)}</>
                                ) : (
                                  <></>
                                )}
                                <span> 04:11 AM</span>
                              </h6>
                            </div>
                            <div className="progress_comment">
                              <h2>Order confirm</h2>
                              <p>Your order has been confirmed.</p>
                            </div>
                          </li>
                          <li className="pending_step">
                            <div className="icon_with_time">
                              <span className="icon">
                                <img src={order_processed_icon} />
                              </span>
                              <h6 className="date_text">
                                {OrderInfo ? (
                                  <>{formatDate(OrderInfo.created_at)}</>
                                ) : (
                                  <></>
                                )}
                                <span> 04:11 AM</span>
                              </h6>
                            </div>
                            <div className="progress_comment">
                              <h2>Order processed</h2>
                              <p>We are preparing your order.</p>
                            </div>
                          </li>
                          <li className="pending_step">
                            <div className="icon_with_time">
                              <span className="icon">
                                <img src={ready_to_pickup_icon} />
                              </span>
                              <h6 className="date_text">
                                {OrderInfo ? (
                                  <>{formatDate(OrderInfo.created_at)}</>
                                ) : (
                                  <></>
                                )}
                                <span> 04:11 AM</span>
                              </h6>
                            </div>
                            <div className="progress_comment">
                              <h2>Ready to pickup</h2>
                              <p>Your order id ready for pickup.</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <div className="LoderSpinnerDiv">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </div>

                <Link className="btn" to={`/product`}>
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default OrderInfo;
