import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ApiUrl, addToCartApi } from "../../helpers/helpers";
import { useSelector, useDispatch } from 'react-redux';
import { incrementCartCount, decrementCartCount } from '../../actions/ActionTypes'; // Import your action creators


import axios from "axios";

const QuantitySelector = ({ productId, image, name, price }) => {
  const dispatch = useDispatch(); // Get the dispatch function from React Redux

  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false); // New loading state

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
   
    setQuantity(quantity + 1);
  };

  const addToCart = async () => {
    setLoading(true); // Set loading to true when starting addToCart process

    try {
      const existingCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingItem = existingCartItems.find(
        (item) => item.productId === productId
      );

      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        dispatch(incrementCartCount());
        const newItem = {
          productId,
          image,
          name,
          price,
          quantity,
        };

        existingCartItems.push(newItem);
      }
      const userInfotoken = localStorage.getItem("token");
      if (userInfotoken !== undefined || userInfotoken !== "") {
        await addToCartApi(existingCartItems, userInfotoken);
      }
      
      localStorage.setItem("cartItems", JSON.stringify(existingCartItems));
      console.log(existingCartItems.length);
      console.log("Added to cart:", existingCartItems);
      toast.success("Product Added In to Cart");
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add product to cart");
    } finally {
      setLoading(false); // Set loading back to false after addToCart process
    }
  };

  return (
    <div className="d-inline-block w-100">
      <div className="quantity-box">
        <button className="quantity-button" onClick={decreaseQuantity}>
          -
        </button>
        <input
          type="text"
          className="quantity-input"
          id="quantity"
          value={quantity}
          readOnly
        />
        <button className="quantity-button" onClick={increaseQuantity}>
          +
        </button>
      </div>
      <div className="d-inline-block w-100">
        <button className="btn btn_buy_now" onClick={addToCart} disabled={loading}>
          {loading ? 'Adding to Cart...' : 'Add to Cart'}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default QuantitySelector;
