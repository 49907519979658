import React from "react";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";

function CancellationRefundPolicyPage() {
  return (
    <>
      <Header></Header>

      <div className="page-content">
        <div>
          <div>
            <div>
              <b>Cancellation and Refund Policy</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>1. Cancellation Policy</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              You have the right to cancel your order within [insert number]
              days of placing the order, provided that the products have not
              been shipped. To cancel an order, please contact our customer
              support at [insert contact information]. Once your order has been
              shipped, it cannot be canceled.
            </div>
            <div>
              <b>
                <br />
              </b>
            </div>
            <div>
              <b>2. Refund Policy</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>a. Damaged or Defective Products:</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              If you receive damaged or defective products, please contact us
              immediately at [insert contact information]. We will arrange for a
              replacement or provide a full refund, including shipping costs.
            </div>
            <div>
              <b>
                <br />
              </b>
            </div>
            <div>
              <b>b. Unopened Products:</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              If you wish to return unopened products, you may do so within
              [insert number] days of receiving your order. The products must be
              in their original condition and packaging. Refunds will be issued
              for the product cost only, excluding shipping charges. You are
              responsible for the return shipping costs.
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>c. Change of Mind:</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              We do not accept returns or provide refunds for products due to a
              change of mind.
            </div>
            <div>
              <br />
            </div>
            <div>
              <b></b>
            </div>
            <div>
              <br />
            </div>
            <div>
              To request a refund or cancellation, please contact our customer
              support at [insert contact information]. Provide your order
              number, details of the product, and the reason for the refund or
              cancellation request. Our team will guide you through the process.
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>4. Processing Time</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              Refunds will be processed within [insert number] business days
              after receiving the returned products or approving the
              cancellation request. The actual time it takes for the funds to
              appear in your account may vary depending on your bank or payment
              provider.
            </div>
            <div>
              <br />
            </div>
            <div>
              <b>5. Contact Information</b>
            </div>
            <div>
              <br />
            </div>
            <div>
              For any questions regarding our Cancellation and Refund Policy,
              please contact us at [insert contact information].
            </div>
            <div>
              <br />
            </div>
            <div>
              Note: This Cancellation and Refund Policy is subject to change
              without notice. Please review this policy periodically for any
              updates.
            </div>
            <div>
              <br />
            </div>
            <div>Last updated: [Insert Date]</div>
          </div>
        </div>
        <div>
          <br />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default CancellationRefundPolicyPage;
