import React from "react";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";


function PrivacyPolicyPage() {
  return (
    <>
      <Header></Header>

      <div className="page-content">
        <div>
          <b>Privacy Policy for Carabao E-commerce Energy Drink</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>Effective Date:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          Thank you for choosing Carabao E-commerce Energy Drink. This Privacy
          Policy outlines how we collect, use, disclose, and protect your
          personal information. By using our website, you agree to the terms
          outlined in this policy.
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>1. Information We Collect:</b>
        </div>
        <div>
          <br />
        </div>
        <div>We may collect the following types of information:</div>
        <div>
          <br />
        </div>
        <div>
          Personal Information: Name, address, email, phone number, and other
          contact details.
        </div>
        <div>
          Payment Information: Credit card details or other payment information
          for transactions.
        </div>
        <div>
          Account Information: Usernames, passwords, and other account-related
          details.
        </div>
        <div>
          Usage Information: Information about how you use our website,
          including IP address, browser type, and device information.
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>2. How We Use Your Information:</b>
        </div>
        <div>
          <br />
        </div>
        <div>We use your information for the following purposes:</div>
        <div>
          <br />
        </div>
        <div>Process and fulfill your orders.</div>
        <div>
          Communicate with you regarding your orders, promotions, and updates.
        </div>
        <div>Improve our website and services.</div>
        <div>Personalize your experience and provide tailored content.</div>
        <div>Ensure the security of our website and prevent fraud.</div>
        <div>
          <br />
        </div>
        <div>
          <b>3. Information Sharing:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as described in this
          Privacy Policy. We may share your information with:
        </div>
        <div>
          <br />
        </div>
        <div>
          Service Providers: Third-party service providers who assist us in
          operating our website and conducting our business.
        </div>
        <div>
          Legal Compliance: When required by law or in response to a legal
          request.
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>
            <br />
          </b>
        </div>
        <div>
          <b>4. Cookies and Tracking:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          We use cookies and similar tracking technologies to enhance your
          experience on our website. You can control cookies through your
          browser settings.
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>5. Your Choices:</b>
        </div>
        <div>
          <br />
        </div>
        <div>You have the right to:</div>
        <div>
          <br />
        </div>
        <div>Access, correct, or delete your personal information.</div>
        <div>Opt-out of marketing communications.</div>
        <div>Disable cookies through your browser settings.</div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>6. Security:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          We take reasonable measures to protect your personal information.
          However, no method of transmission over the internet or electronic
          storage is 100% secure.
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>7. Changes to This Privacy Policy:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          We may update our Privacy Policy from time to time. The latest version
          will be posted on this page with the effective date.
        </div>
        <div>
          <br />
        </div>
        <div>
          <b>8. Contact Us:</b>
        </div>
        <div>
          <br />
        </div>
        <div>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at [insert contact email/phone].
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default PrivacyPolicyPage;
