import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import { ApiUrl, formatDate } from "../helpers/helpers";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function MyOrders() {
  const [cartItems, setCartItems] = useState(null);
  const [apiResponce, setApiResponce] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token !== undefined && token !== "") {
          const response = await axios.get(`${ApiUrl}orderList`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data.data, "API response");
          setApiResponce(response.data.data);
          const storedCartItems = JSON.parse(response.data.data.cart);
          setCartItems(storedCartItems);
          //  setAmount(storedCartItems ? calculateTotal(storedCartItems) : 0);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className="grids">
        <div className="container pt-4 mt-3">
          {/* <div className="top_step_navigation"></div> */}
          <div className="page_content_box my_orders_page_ui">
            <h1 className="page_title text-start mb-3">
              My <span>Orders</span>{" "}
            </h1>
            <div className="row flex-row-reverse">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-12">
                <div className="">
                  {apiResponce ? (
                    apiResponce.length > 0 ? (
                      apiResponce.map((order, index) => {
                        // Parse the cart JSON string
                        const cartItems = JSON.parse(order.cart);

                        return (
                          <div
                            key={index}
                            className="sidebar_cover ms-0 mb-4 mt-0"
                          >
                            <Link to={`/orderInfo/${order.id}`}>
                              <ul className="purchase_heightlights">
                                <li>
                                  <p>Date</p>
                                  <h4>{formatDate(order.created_at)}</h4>
                                </li>
                                <li>
                                  <p>order number</p>
                                  <h4>{order.order_id}</h4>
                                </li>
                                {/* <li>
                <p>payment method</p>
                <h4>master card</h4>
              </li> */}
                                <li>
                                  <p>payment Id</p>
                                  <h4>{order.transaction_number}</h4>
                                </li>
                                <li>
                                  <p>Order Amount </p>
                                  <h4> ₹ {order.state_price / 100}</h4>
                                </li>
                                <li>
                                  <p>Order Status </p>
                                  <h4>  {order.order_status}</h4>
                                </li>
                              </ul>

                              <div className="cart_product_box row">
                                {cartItems.map((item, itemIndex) => (
                                  <div
                                    key={itemIndex}
                                    className="product_item col-3 text-center"
                                  >
                                    <div className="product_image pe-4 mb-3">
                                      <img
                                        src={item.image}
                                        alt={`cart_product_img_${itemIndex}`}
                                      />
                                    </div>
                                    <div className="product_description flex-grow-1">
                                      <div className="product_name_with_buttons">
                                        <h3 className="product_title">
                                          {item.name}
                                        </h3>
                                        <div className="product_price_quantity">
                                          <h4 className="product_price">
                                            {item.price} * {item.quantity}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <p className="noIteminCart">No Data Found</p>
                    )
                  ) : (
                    <div className="LoderSpinnerDiv">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default MyOrders;
