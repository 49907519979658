import React, { useState, useEffect } from "react";
import "../css/style.css";
import banner_img from "../images/banner_img.png";
import play_video_icon from "../images/play_video_icon.svg";
import heightlight_light_left from "../images/heightlight_light_left.png";
import main_product_text_bigger_img from "../images/main_product_text_bigger_img.svg";
import main_product_flash_img from "../images/main_product_flash_img.svg";
import main_product_bottle_big from "../images/main_product_bottle_big.svg";
import flash_small_icon from "../images/flash_small_icon.svg";
import milk_bottle_icon from "../images/milk_bottle_icon.svg";
import temprature_icon from "../images/temprature_icon.svg";
import arrow_right_icon from "../images/arrow_right_icon.svg";
import heightlight_light from "../images/heightlight_light.png";
import hurdle_layer_img from "../images/hurdle_layer_img.svg";
import product_with_package_bottles_img from "../images/product_with_package_bottles_img.png";
import more_option_btn from "../images/more_option_btn.svg";
import add_cart_icon from "../images/add_cart_icon.svg";
import why_carb_light_left from "../images/why_carb_light_left.png";
import energy_light_icon from "../images/energy_light_icon.svg";
import boost_light_icon from "../images/boost_light_icon.svg";
import slimline_light_icon from "../images/slimline_light_icon.svg";
import calories_light_icon from "../images/calories_light_icon.svg";
import why_carbs_img from "../images/why_carbs_img.png";
import blog_one from "../images/insta1.jpg";
import blog_two from "../images/insta6.jpg";
import blog_three from "../images/insta3.jpg";
import blog_four from "../images/insta4.jpg";
import blog_five from "../images/insta5.jpg";
import contact_height_light_img from "../images/contact_height_light_img.png";
import instagram_icon from "../images/instagram_icon.svg";
import now_available_in_india_text from "../images/now_available_in_india_text.svg";
import fuel_product_one from "../images/fuel_product_one.png";
import wishlist_icon from "../images/wishlist_icon.svg";
import amazon_logo from "../images/amazon_logo.png";
import flipkart_logo from "../images/flipkart-logo.png";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import ProductList from "./components/ProductsComponent";
import Modal from "react-bootstrap/Modal";
import YouTube from 'react-youtube';
import { Link } from "react-router-dom";
import ContactUs from "./components/ContactUs";


function FrontPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
      <Header navigateRoute={"/"}></Header>
      <Modal show={show} className="video_modal_ui" onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><YouTube videoId="61_uHsVJHR0" opts={opts}  /></Modal.Body>
      </Modal>
      <section className="landing_page_banner">
        <div className="container-fluid">
          <div className="banner_featured_img">
            <div className="home_page_banner_content">
              <p>Get ready to bring it on change to</p>

              <h1>
                <div className="d-block">
                  {" "}
                  IGNITE <span className="text_stroke">THE</span>{" "}
                </div>
                <div className="d-block"> FIGHTING</div> SPIRIT
              </h1>
            </div>

            <img src={banner_img} alt="banner_img" />
            <a
              className="video_play_link video-btn"
            >
              <div className="gallery-image">
                <img
                  src={play_video_icon}
                  alt="play_video_icon"
                  onClick={handleShow}
                />

              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="main_product_flash_sale_banner_sec">
        <span className="heighlight_left_light">
          {" "}
          <img src={heightlight_light_left} alt="heightlight_light_left" />
        </span>
        <div className="main_product_text_bigger_img">
          {" "}
          <img
            src={main_product_text_bigger_img}
            alt="main_product_text_bigger_img"
          />{" "}
        </div>
        <div className="main_product_flash_img">
          {" "}
          <img src={main_product_flash_img} alt="main_product_flash_img" />
        </div>
        <div className="container">
          <div className="full_container">
            <div className="product_price_image_features">
              <div className="product_sale_price">
                <img
                  src={now_available_in_india_text}
                  alt="now_available_in_india_text"
                />
              </div>
              <div className="product_big_img">
                {" "}
                <img
                  src={main_product_bottle_big}
                  alt="main_product_bottle_big"
                />
              </div>
              <div className="product_features">
                <div className="w-100">
                  <div className="product_features_box">
                    <ul className="d-flex align-items-center">
                      <li>
                        <a href="#">
                          <img src={flash_small_icon} alt="flash_small_icon" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={milk_bottle_icon} alt="milk_bottle_icon" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={temprature_icon} alt="temprature_icon" />
                        </a>
                      </li>

                      <li>
                        <p>
                          <span>PER SERVE</span>
                          330 Ml
                        </p>
                      </li>
                    </ul>
                    <div className="progress_bar_box">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Caffeine</span>
                        <span>99 MG</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ marginRight: "75%" }}
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>

                    <div className="progress_bar_box">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Sugar</span>
                        <span>36 G</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ marginRight: "75%" }}
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>

                    <div className="progress_bar_box">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Calories</span>
                        <span>148 CAL</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ marginRight: "75%" }}
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="shop_now_btn_div">
                    <Link to="/product" className="btn w-100">
                      Shop now
                      <span className="icon">
                        <img src={arrow_right_icon} alt="arrow_right_icon" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="grids">
        <div className="position-relative">
          <span className="heighlight_right_light">
            {" "}
            <img src={heightlight_light} alt="heightlight_light" />{" "}
          </span>
          <div className="container">
            <div className="product_explore_box">
              <h2 className="very_big_title">
                <span>Get Ready to</span>
                Bring it on
              </h2>
              <div className="row align-items-end">
                <span className="hurdle_layer">
                  {" "}
                  <img src={hurdle_layer_img} alt="hurdle_layer_img" />{" "}
                </span>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="featured_img">
                    <img
                      src={product_with_package_bottles_img}
                      alt="product_with_package_bottles_img"
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="product_description">
                    <p>
                      {" "}
                      In 2002, Aed Carabao, the lead singer of Thailand’s most
                      influential and successful Carabao band and his
                      industrialist friend Sathien Setthasit co-founded the
                      Carabao energy drink brand.
                    </p>

                    <p>
                      {" "}
                      The Carabao brand has reached the world! The sales network
                      covers all continents, more than 40 countries and regions.
                      The Carabao brand gives everyone the spirit and strength
                      to overcome challenges, fills everyone with fighting
                      spirit, challenges the impossible in life, and keeps
                      moving toward the goal!{" "}
                    </p>

                    <p>
                      Arneja Foods is a leading food distribution company based
                      in the vibrant state of Punjab, India. Our company takes
                      pride in embodying the culinary legacy and hospitality
                      that Punjab’s are renowned for with a passion for serving
                      quality food, we have ventured into the food distribution
                      industry, establishing ourselves as a trusted name in the
                      market. we have been committed to partnering with esteemed
                      food brands to create a strong presence in the North
                      Indian market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-inline-block w-100 text-center margin_bottom_adjust">
              <Link to={'product'} >
                <button className="btn explore_more_btn">
                  Explore more{" "}
                  <span className="icon">
                    <img src={arrow_right_icon} alt="arrow_right_icon" />
                  </span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <section className="more_fuel_for_file_sec">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center border_bottom_1px px-3">
              <h3 className="main_title">MORE FUEL FOR YOUR FIRE</h3>

            
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane show active"
                id="pills-cans_tab"
                role="tabpanel"
                aria-labelledby="pills-cans_tab-tab"
                tabindex="0"
              >
                <div className="px-3">
                  <div className="cans_products_slider">
                    <ProductList></ProductList>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">

                 <Link to={'product'} ><button className="btn explore_more_btn">View All</button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why_carabao_section">
          <span className="why_carb_light_left">
            {" "}
            <img src={why_carb_light_left} alt="why_carb_light_left" />{" "}
          </span>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <h3 className="main_title">Why Carabao ?</h3>
                <ul className="carbas_list_with_icons d-flex flex-wrap">
                  <li>
                    <div className="box_icon_with_text">
                      {" "}
                      <span className="icon">
                        <img src={energy_light_icon} />
                      </span>
                      <div className="description">
                        <h5>Energy for all Occasions</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="box_icon_with_text">
                      {" "}
                      <span className="icon">
                        <img src={slimline_light_icon} />
                      </span>
                      <div className="description">
                        <h5>330ml Slimline Cans</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="box_icon_with_text">
                      {" "}
                      <span className="icon">
                        <img src={boost_light_icon} />
                      </span>
                      <div className="description">
                        <h5>Boost with Zero Crash</h5>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="box_icon_with_text">
                      {" "}
                      <span className="icon">
                        <img src={calories_light_icon} />
                      </span>
                      <div className="description">
                        <h5>
                          Contain Vitamin
                          <br /> B inside
                        </h5>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="d-inline-block">
                <Link to={'product'} className="btn explore_more_btn">
                    Shop Now
                    <span className="icon">
                      <img src={arrow_right_icon} alt="arrow_right_icon" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="featured_img">
                  <img src={why_carbs_img} alt="why_carbs_img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="shop_your_way_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-5">
                <div className="shop_your_way_heading">
                  <h2>Also Available online </h2>
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <div className="company_logos">
                  <ul className="d-flex align-items-center flex-wrap">
                    <li className="flex-grow-1">
                      <img src={amazon_logo} alt="amazon_logo" />
                    </li>
                    <li className="flex-grow-1">
                      <img src={flipkart_logo} alt="flipkart_logo" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="get_ready_game_sec position-relative">
          <div className="container">
            <div className="get_ready_game_content">
              <h2>
                {" "}
                Ignite
                <span className="d-block">the fighting </span>
                Spirit{" "}
              </h2>
            </div>
          </div>
        </section>
        <section className="our_blogs_sec pt-5 mt-4">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center border_bottom_1px px-3">
              <h3 className="main_title">Follow @thecarabao</h3>
            </div>
            <div className="instagram_feed_sec">
              <div className="spacing">
                <div className="instagram_thumbnail_box">
                  <div className="product_img pb-0">
                    <img src={blog_one} alt="blog_one" />
                  </div>
                  <a href="https://www.instagram.com/p/C1jBFzcrasr/"  target="_blank">
                    <img src={instagram_icon} alt="instagram_icon" />
                  </a>
                </div>
              </div>

              <div className="spacing">
                <div className="instagram_thumbnail_box">
                  <div className="product_img pb-0">
                    <img src={blog_two} alt="blog_two" />
                  </div>
                  <a href="https://www.instagram.com/p/C1tgCr7NoRN/" target="_blank">
                    <img src={instagram_icon} alt="instagram_icon" />
                  </a>
                </div>
              </div>
              <div className="spacing">
                <div className="instagram_thumbnail_box">
                  <div className="product_img pb-0">
                    <img src={blog_three} alt="blog_three" />
                  </div>
                  <a href="https://www.instagram.com/p/C30DVv7PmVb/" target="_blank">
                    <img src={instagram_icon} alt="instagram_icon" />
                  </a>
                </div>
              </div>
              <div className="spacing">
                <div className="instagram_thumbnail_box">
                  <div className="product_img pb-0">
                    <img src={blog_four} alt="blog_four" />
                  </div>
                  <a href="https://www.instagram.com/p/C3fbcXnPyUE/" target="_blank">
                    <img src={instagram_icon} alt="instagram_icon"  />
                  </a>
                </div>
              </div>
              <div className="spacing">
                <div className="instagram_thumbnail_box">
                  <div className="product_img pb-0">
                    <img src={blog_five} alt="blog_five" />
                  </div>
                  <a href="https://www.instagram.com/p/C03RbL-vhQQ/" target="_blank">
                    <img src={instagram_icon} alt="instagram_icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="contact_us_section">
        <span className="heighlight_right_light">
          {" "}
          <img src={heightlight_light} alt="heightlight_light" />{" "}
        </span>

        <span className="contact_height_light_img">
          <img src={contact_height_light_img} alt="contact_height_light_img" />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 mt-4">
              <div className="contact_us_heading">
                <h2> Have a Carabao! Let’s Discuss </h2>
                <p>
                  Thank you for getting in touch! Kindly. Fill the form, have a
                  great day!{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-0">
            <ContactUs/>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default FrontPage;
