// src/components/ProductList.js
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchProducts } from "../../actions/productActions";
import { addToCart } from "../../actions/cartActions";

import fuel_product_one from "../../images/fuel_product_one.png";
import wishlist_icon from "../../images/wishlist_icon.svg";
import more_option_btn from "../../images/more_option_btn.svg";
import add_cart_icon from "../../images/add_cart_icon.svg";
import { imageUrl } from "../../helpers/helpers";
import "../../css/style.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AddToCartButtonSingle from "./AddToCartButtonSingle";
import Spinner from "react-bootstrap/Spinner";


const ProductList = ({
  products,
  loading,
  error,
  fetchProducts,
  addToCart,
}) => {
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  if (loading) {
    return (
      <>
        <div className="LoderSpinnerDiv">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {products.map((product) => (
          <div className="spacing">
            <div className="product_box">
              <div className="product_img">
                <Link
                  className="nav-link"
                  to={`/product-details/${product.slug}`}
                >
                  <img
                    src={product ? imageUrl + product.photo : ""}
                    className="ListImageProduct"
                    alt="fuel_product_one"
                  />
                  {/* <span className="product_discount">10% Off</span> */}
                  <a href="#" className="add_to_wishlist">
                    {/* <img src={wishlist_icon} /> */}
                  </a>
                </Link>
              </div>

              <div className="d-flex justify-content-between">
                <div className="product_name_with_quantity">
                  <Link
                    className="nav-link"
                    to={`/product-details/${product.slug}`}
                  >
                    <h4 className="product_name"> {product.name}</h4>
                  </Link>
                  {/* <p className="productt_quantity">330ml</p> */}
                </div>
                {/* <div className="more_options_btn">
                  <a href="#">
                    <img src={more_option_btn} alt="momore_option_btnre" />
                  </a>
                </div> */}
              </div>
              <div className="d-flex align-items-end justify-content-between pt-3 pb-2">
                <div className="product_price">
                  {/* <span className="regular_price">₹89</span> */}
                  <span className="sale_price">{product.discount_price}</span>
                </div>
                <div className="add_to_cart_btn_div">
                  {/* <button className="add_to_cart_btn" onClick={() => addToCart(product)} >
                Add to Cart
                  <span className="icon">
                    <img src={add_cart_icon} alt="add_cart_icon" />
                  </span>
                </button> */}
                  <AddToCartButtonSingle
                    productId={product ? product.id : ""}
                    name={product ? product.name : ""}
                    image={product ? imageUrl + product.photo : ""}
                    price={product ? product.discount_price : ""}
                    quantity={1}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.products,
  loading: state.products.loading,
  error: state.products.error,
});

export default connect(mapStateToProps, { fetchProducts, addToCart })(
  ProductList
);
