import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { incrementCartCount, decrementCartCount } from '../../actions/ActionTypes'; // Import your action creators

import toast, { Toaster } from "react-hot-toast";
import { addToCartApi, updateCartItemCount } from "../../helpers/helpers";
import add_cart_icon from "../../images/add_cart_icon.svg";


const AddToCartButtonSingle = ({ productId, image, name, price, quantity }) => {
  const dispatch = useDispatch(); // Get the dispatch function from React Redux

  const [loading, setLoading] = useState(false);
  const quantityInt = parseInt(quantity, 10);

  const addToCart = async () => {
    setLoading(true);
    const existingCartItems =
      JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingItem = existingCartItems.find(
      (item) => item.productId === productId
    );

    if (existingItem) {
      existingItem.quantity += quantityInt;
    } else {
      dispatch(incrementCartCount());

      const newItem = {
        productId,
        image,
        name,
        price,
        quantity,
      };
  
      existingCartItems.push(newItem);
    }
    const updatedCartItemCount = updateCartItemCount();
    console.log(updatedCartItemCount);
    const userInfotoken = localStorage.getItem("token");
    if (userInfotoken !== undefined || userInfotoken !== "") {
      await addToCartApi(existingCartItems, userInfotoken);
    }

    localStorage.setItem("cartItems", JSON.stringify(existingCartItems));
 
    // console.log("Added to cart:", existingCartItems);
    toast.success("Product Added In to Cart");
    setLoading(false);
  };

  return (
    <>
      <button
        className="btn add_to_cart_btn"
        onClick={addToCart}
        disabled={loading}
      >
        {loading ? "Adding..." : "Add to Cart"}
        <span className="icon">
          <img src={add_cart_icon} alt="add_cart_icon" />
        </span>
      </button>
      <Toaster
      />
    </>
  );
};

export default AddToCartButtonSingle;
