import React, { useState, useEffect } from "react";
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import main_product_text_bigger_img from "../images/main_product_text_bigger_img.svg";
import arrow_right_icon from "../images/arrow_right_icon.svg";
import product_banner_flash from "../images/product_banner_flash.png";
import main_product_bottle_big from "../images/main_product_bottle_big.svg";
import product_page_video_section_img from "../images/product_page_video_section_img.png";
import freshing_green_left_light from "../images/freshing_green_left_light.png";
import never_ending_energy_img from "../images/never_ending_energy_img.png";
import athletes_icon from "../images/athletes_icon.svg";
import sports_professions_icon from "../images/sports_professions_icon.svg";
import fitness_anthusis_icon from "../images/fitness_anthusis_icon.svg";
import working_professionals_icon from "../images/working_professionals_icon.svg";
import play_video_icon from "../images/play_video_icon.svg";
import MerchantProducts from './components/MerchantProducts';
import YouTube from 'react-youtube';
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";


function MerchandisePage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
      <Header navigateRoute={'product'}></Header>
      <Modal show={show} className="video_modal_ui" onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><YouTube videoId="61_uHsVJHR0" opts={opts}  /></Modal.Body>
      </Modal>
      <section className="product_page_banner">
        <span className="main_product_text_bigger_img">
          <img
            src={main_product_text_bigger_img}
            alt="main_product_text_bigger_img"
          />{" "}
        </span>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
              <div className="product_banner_content">
                <h5>Evolve your game with</h5>
                <h2> Merchant product</h2>
                <p>
                  Carabao Energy Drink Original is a refreshing and great
                  tasting low calorie energy drink. Enjoy our original,
                  distinctive taste in this light and refreshingly carbonated
                  energy drink.
                </p>
               
                <Link to={'/product'} className="btn" >
                  Shop now
                  <span className="icon">
                    <img
                      src={arrow_right_icon}
                      alt="arrow_right_icon"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
              <div className="product_page_banner_imgs">
                <span className="banner_flash_img">
                  <img
                    src={product_banner_flash}
                    alt="product_banner_flash"
                  />
                </span>
                <span className="main_product_bottle_big">
                  {" "}
                  <img src={main_product_bottle_big} alt="rr" />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="landing_page_banner">
          <div className="container-fluid">
            <div className="banner_featured_img">
              <img
                src={product_page_video_section_img}
                alt="product_page_video_section_img"
              />
              <a
                className="video_play_link video-btn"
              >
                <div className="gallery-image">
                  <img src={play_video_icon} alt="play_video_icon" onClick={handleShow} />
                </div>
              </a>
            </div>
          </div>
        </div> */}
      </section>

      <div className="grids">
        <section className="more_fuel_for_file_sec product_more_fuel_sec">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-center align-items-center px-3 pb-3">
              <h3 className="main_title">MORE FUEL FOR YOUR FIRE</h3>
            </div>

              <MerchantProducts></MerchantProducts>
           
          
          </div>
        </section>

        {/* <section className="feel_the_freshing_section feel_freshing_section_bg_sec">
          <span className="freshing_green_light_left">
            {" "}
            <img
              src={freshing_green_left_light}
              alt="freshing_green_left_light"
            />{" "}
          </span>
          <span className="freshing_green_light_right">
            {" "}
            <img
              src={freshing_green_left_light}
              alt="freshing_green_left_light"
            />{" "}
          </span>
          <div className="container">
            <div className="feel_freshing_section_bg ">
              <div className="feel_freshing_heading">
                <h3>featured products</h3>
                <h2>Feel the refreshing taste</h2>
                <p>
                  Carabao original Carabao Energy Drink Original is a refreshing
                  and great tasting low calorie energy drink. Enjoy our
                  original, distinctive taste in this light and refreshingly
                  carbonated energy drink.
                </p>
              </div>

              <div className="why_carabao_section product_why_carabao">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <h3 className="main_title never_ending_energy_heading">
                        never ending energy for ?
                      </h3>
                      <ul className="carbas_list_with_icons green_text_list d-flex flex-wrap">
                        <li>
                          <div className="box_icon_with_text">
                            <span className="icon">
                              <img src={athletes_icon} />
                            </span>
                            <div className="description">
                              <h5>Athletes</h5>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="box_icon_with_text">
                            <span className="icon">
                              <img src={sports_professions_icon} />
                            </span>
                            <div className="description">
                              <h5>
                                Sports Professionals
                              </h5>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="box_icon_with_text">
                            <span className="icon">
                              <img src={fitness_anthusis_icon} />
                            </span>
                            <div className="description">
                              <h5>
                                Fitness  Enthusiast
                              </h5>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="box_icon_with_text">
                            <span className="icon">
                              <img src={working_professionals_icon} />
                            </span>
                            <div className="description">
                              <h5>
                                Working
                                Professionals
                              </h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="featured_img mt-0">
                        <img
                          src={never_ending_energy_img}
                          alt="never_ending_energy_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <Footer></Footer>
    </>
  );
}

export default MerchandisePage;
