import {
  INCREMENT_CART_COUNT,
  DECREMENT_CART_COUNT,
  RESET_CART_COUNT,
} from "../actions/ActionTypes";

const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
let cartItemsCount = cartItems.length;
const initialState = {
  count: cartItemsCount,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_CART_COUNT:
      return {
        ...state,
        count: state.count + 1,
      };
    case DECREMENT_CART_COUNT:
      return {
        ...state,
        count: state.count - 1,
      };
    case RESET_CART_COUNT:
      return {
        ...state,
        count: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
