
import axios from "axios";

// Define your variables
const imageUrl = 'https://carabao.in/backend/assets/images/';
const ApiUrl = 'https://carabao.in/backend/api/';

//  const imageUrl = 'http://localhost/YNR/Laravel/carabao-backend/Carabao-backend/assets/images/';
//  const ApiUrl = 'http://localhost/YNR/Laravel/carabao-backend/Carabao-backend/api/';
 const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(window.Razorpay);
      };
      document.body.appendChild(script);
    });
  };
  
  const addToCartApi = async (cartItems, token) => {
    try {
      await axios.post(
        `${ApiUrl}addToCart`,
        { cartItems },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Login error:", error);
    }
  };


  const  formatDate = (dateString) => {
    // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    const options = { day: 'numeric',month: 'long', year: 'numeric' };

    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const  generateRandomString = ()  =>{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 25; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

// cartHelper.js
 const updateCartItemCount = () => {
  console.log('updateCartItemCount');
  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const cartItemCount = cartItems.length;
  return cartItemCount;
};


  
// Export the variables
export { imageUrl, ApiUrl, loadScript, addToCartApi, formatDate, generateRandomString, updateCartItemCount };
