/* eslint-disable jsx-a11y/anchor-is-valid */
import "../css/style.css";
import footer_logo from "../images/footer_logo.svg";
import footer_layer_img from "../images/footer_layer_img.png";
import facebook_icon from "../images/facebook_icon.svg";
import instagram_icon_white from "../images/instagram_icon_white.svg";
import twitter_icon from "../images/twitter.png";
import youtube from "../images/youtube.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="main_footer">
        <div className="container">
          <span className="footer_layer">
            <img src={footer_layer_img} alt="footer_layer_img" />{" "}
          </span>
          <div className="row position-relative">
            <div className="col-sm-12 col-md-3 col-lg-4 mt-4 pt-2">
              <div className="footer_logo">
                <img src={footer_logo} alt="footer_logo" />
              </div>
            </div>

            <div className="col-sm-4 col-md-3 col-lg-3 mt-4 pt-2">
              <div className="footer_widget">
                <h3 className="footer_title"> Quick Links</h3>
                <ul className="quick_links">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>
                    <Link to={`/product`}>Shop</Link>

                  </li>
                  <li>
                    <Link to={`/my-orders`}>Orders</Link>

                  </li>
                 
                  <li>
                    <Link to={`/cancellation-refund-policy`}>Cancellation Refund Policy</Link>

                  </li>
                  <li>
                    <Link to={`/terms-and-conditions`}>Terms And Conditions</Link>

                  </li>
                
                </ul>
              </div>
            </div>

            <div className="col-sm-4 col-md-3 col-lg-3 mt-4 pt-2">
              <div className="footer_widget">
                <h3 className="footer_title"> Company</h3>
                <ul className="quick_links">
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <Link to={`/contact-us`}>Contact Us</Link>

                  </li>
                  <li>
                    <a href="#">Blogs</a>
                  </li>
                  <li>
                    <Link to={`/privacy-policy`}>Privacy Policy</Link>

                  </li>
                  <li>
                    <Link to={`/shipping-delivery-policy`}>Shipping Delivery Policy</Link>

                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-4 col-md-3 col-lg-2 mt-4 pt-2">
              <div className="footer_widget">
                <h3 className="footer_title"> Follow us</h3>
                <ul className="social_media_links">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61554590121269&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                      <img src={facebook_icon} alt="icon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/carabao.in?igsh=NGNjMmxvd2tvOWk2"  target="_blank" rel="noreferrer">
                      <img src={instagram_icon_white} alt="icon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Carabao_In" target="_blank" rel="noreferrer">
                      <img src={twitter_icon} alt="icon" style={{ 
                              width: '26px',
                              backgroundColor: 'white',
                              borderRadius: '5px'
                       }} />
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com/@CarabaoIndia?si=NCzioL-jg2629TyS" target="_blank" rel="noreferrer">
                      <img src={youtube} style={{ width: '31px' }}  alt="icon"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
