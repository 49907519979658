import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { ApiUrl, addToCartApi } from '../helpers/helpers';
import "../css/style.css";
import Header from "../inculde/Header";
import Footer from "../inculde/Footer";
import main_product_text_bigger_img from "../images/main_product_text_bigger_img.svg";
import about_page_lights_left from "../images/about_page_lights_left.png";
import hurdle_layer_img from "../images/hurdle_layer_img.svg";
import login_page_banner from "../images/login_page_banner.png";
import google_icon from "../images/google_icon.png";
import facebook_blue_icon from "../images/facebook_blue_icon.png";
import icloud_icon from "../images/icloud_icon.png";
import { useNavigate } from 'react-router-dom';


function LoginPage() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!userData.email || !userData.password) {
      setError('Please fill out all fields');
      return false;
    }
    setError('');
    return true;
  };

  const loginUser = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!validateForm()) return;
    try {
      setIsLoading(true); // Set loading state to true
      const response = await axios.post(`${ApiUrl}login`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      }); 
      const { token, first_name, email } = response.data.data;
      localStorage.setItem('token', token);
      localStorage.setItem('first_name', first_name);
      localStorage.setItem('email', email);
      const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
      if(storedCartItems !== undefined || storedCartItems !== '' ){
        await  addToCartApi(storedCartItems,token);
      }
      toast.success('User Login Successfully');
      navigate('/');

    } catch (error) {
      toast.error('Please check your login details');
      console.error('Login error:', error);
    } finally {
      setIsLoading(false); // Set loading state to false after API request completes
    }
  };

  return (
    <>
      <Header />
      <section className="product_page_banner login_banner_section">
        <span className="main_product_text_bigger_img">
          <img src={main_product_text_bigger_img} alt="main_product_text_bigger_img" />
        </span>
      </section>
      <div className="grids">
        <section className="our_story_section login_form_area">
          <span className="light_left">
            <img src={about_page_lights_left} alt="about_page_lights_left" />
          </span>
          <span className="light_right">
            <img src={about_page_lights_left} alt="about_page_lights_left" />
          </span>
          <div className="container">
            <div className="login_form">
              <span className="hurdle_layer">
                <img src={hurdle_layer_img} alt="hurdle_layer_img" />
              </span>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="login_form_img">
                    <img src={login_page_banner} alt="login_banner_section" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                  <div className="login_header">
                    <h2>
                      <span> Login </span>
                      Your account
                    </h2>
                  </div>
                  <form onSubmit={loginUser}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email"
                            name="email"
                            onChange={handleInputChange}
                            required // Add required attribute for email
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 px-4 mt-5">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            onChange={handleInputChange}
                            required // Add required attribute for password
                          />
                        </div>
                      </div>
                      {error && (
                        <div className="col-sm-12 col-md-12 px-4 mt-3 text-danger">
                          {error}
                        </div>
                      )}
                      <div className="col-sm-12 col-md-12 px-4 mt-5 text-center">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <button className="btn view_more_btn" type="submit">
                            {isLoading ? 'Logging In...' : 'Login'}
                          </button> 
                          <a href="#" className="forgot_password_link" style={{ visibility: 'hidden' }}>
                            Forgot Password ?
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="text-center">
                    <h4 className="dont_have_account_text">
                      Don’t have an account ? <Link to="/sign-up">Sign up!</Link> 
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Toaster/>
      </div>
      <Footer/>
    </>
  );
}

export default LoginPage;
